import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import axios from 'axios';

import styles from './20240610.module.scss';

const cx = classNames.bind(styles);

const isReal = window.location.hostname === 'theventi.apps.flyground.co.kr';
const HOST_URL = isReal ? 'https://theventi.apis.flyground.co.kr' : 'https://alpha-theventi.apis.flyground.co.kr';

const Page = () => {
  const [receivable, setReceivable] = useState();
  const [processing, setProcessing] = useState(false);
  const accessToken = useRef();

  useMountEffect(() => {
    let rootElement;
    if (/android/i.test(navigator.userAgent)) {
      rootElement = window.document;
    } else {
      rootElement = window;
    }

    rootElement.addEventListener('message', (e) => {
      const message = JSON.parse(e.data);

      if (message.command === 'accessToken') {
        accessToken.current = message.data;
      }
    });

    window.ReactNativeWebView.postMessage(JSON.stringify({ command: 'accessToken' }));
  });

  const done = () => {
    if (processing) return;

    setProcessing(true);

    axios
      .get(`${HOST_URL}/v1/badge/badges/202406/receivable`, {
        headers: {
          Authorization: `Bearer ${accessToken.current}`,
        },
      })
      .then((result) => result.data)
      .then(({ success, data }) => {
        console.debug(success, data);

        if (success && data.receivable) {
          window.ReactNativeWebView?.postMessage(JSON.stringify({ command: 'badge' }));
        } else {
          setReceivable(false);
        }
      });
  };

  const close = () => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ command: 'close' }));
  };

  return (
    <div className={cx('container')}>
      <img src={require('../../assets/images/badge/20240610.jpg')} alt="" />
      {receivable !== false && (
        <>
          <div className={cx('text')}>
            몬스터 신메뉴 중 하나를
            <br />
            픽업오더로 주문하고
            <br />
            6월 뱃지를 달성해 보세요👾
          </div>
          <div className={cx('small')}>※ 구매완료 후 아래 버튼을 클릭해주세요.</div>
          <div onClick={done} className={cx('button')}>
            미션완료
          </div>
        </>
      )}
      {receivable === false && (
        <>
          <div className={cx('text')}>
            주문이 이루어지지 않았어요😭
            <br />
            몬스터 신메뉴 중 하나를
            <br />
            픽업오더로 주문해 주세요!
          </div>
          <div onClick={close} className={cx('button')}>
            확인
          </div>
        </>
      )}
    </div>
  );
};

export default Page;
